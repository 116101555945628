






import Vue from 'vue'
import RatesList from './RatesListPage.vue'
export default Vue.extend({
  // components: { RatesList },
})
